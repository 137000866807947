import { service } from '../index'

// 新闻分类
export function newClassify() {
  return service.post({
    url: '/index/newClassify'
  })
}

// 新闻资讯列表
export function newsList(data) {
  return service.post({
    url: '/index/newsList',
    data
  })
}

// 新闻详情
export function newDetail(data) {
  return service.post({
    url: '/index/newDetail',
    data
  })
}

// 相关推荐
export function newTop() {
  return service.post({
    url: '/index/newTop'
  })
}
